<template>
    <div class="dark:bg-gray-800">
        <Nav />

        <div class="container pt-10">
            <div v-if="loaded">
                <div class="space-y-10">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div
                            class="px-4 py-5 relative sm:grid sm:grid-cols-2 sm:gap-3 items-center"
                        >
                            <h2 class="text-xl font-medium text-teal-700 relative text-left">
                                New Currency
                            </h2>
                        </div>
                        <div
                            class="bg-gray-50 px-6 py-4 sm:grid sm:grid-cols-3 gap-4 items-center"
                            v-if="assets.length"
                        >
                            <label for="search" class="text-sm text-left font-medium text-gray-700">
                                Select existing currency
                            </label>

                            <select
                                class="focus:ring-teal-500 focus:border-teal-500 block border-gray-300 rounded-md"
                                v-model="selectedCurrency"
                            >
                                <option
                                    v-for="{ id, name, symbol } in assets"
                                    :key="symbol"
                                    :value="{ id, name, symbol }"
                                >
                                    {{ symbol }} - {{ name }}
                                </option>
                            </select>
                            <button
                                v-if="search === ''"
                                type="button"
                                @click.prevent="addCurrency"
                                class="ml-5 bg-white py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add to my account
                            </button>
                        </div>
                        <div class="bg-white px-6 py-4 sm:grid sm:grid-cols-3 gap-4 items-center">
                            <label for="search" class="text-sm text-left font-medium text-gray-700">
                                Search a new one
                            </label>
                            <input
                                type="text"
                                id="search"
                                v-model="search"
                                required
                                class="focus:ring-teal-500 focus:border-teal-500 block border-gray-300 rounded-md"
                            />
                            <button
                                type="button"
                                @click.prevent="searchCurrency"
                                class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Search
                            </button>
                        </div>

                        <div
                            class="bg-gray-50 px-6 py-4 sm:grid sm:grid-cols-3 gap-4 items-center"
                            v-if="currencyFound"
                        >
                            <label for="search" class="text-sm text-left font-medium text-gray-700">
                                On currency found
                            </label>

                            <div class="">
                                {{ currencyFound.symbol }} - {{ currencyFound.name }}
                            </div>
                            <button
                                type="button"
                                @click.prevent="addCurrency"
                                class="ml-5 bg-white py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add to my account
                            </button>
                        </div>
                    </div>
                </div>

                <!-- <form @submit.prevent="addCurrency">
                    <div class="col-span-6 sm:col-span-3">
                        <label for="country" class="block text-sm font-medium text-gray-200">
                            Select currency
                        </label>

                        <select
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                            v-model="selectedCurrency"
                        >
                            <option
                                v-for="{ id, name, symbol } in assets"
                                :key="symbol"
                                :value="{ id, name, symbol }"
                            >
                                {{ symbol }} - {{ name }}
                            </option>
                        </select>

                        <div class="mt-5" v-if="selectedCurrency">
                            <button
                                class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 "
                                @click.prevent="addCurrency"
                            >
                                Add currency
                            </button>
                        </div>
                    </div>
                </form> -->
            </div>
            <div
                v-else
                class="dark:bg-gray-800 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
            >
                <Loader />
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { firebaseAssets, firebaseCurrencies } from '@/Firebase'

import Loader from '@/components/Loader'
import Nav from '@/components/Nav'

import errorHandler from '@/helpers/errorHandler'

import { getAsset } from '@/CoinAPI'

export default {
    components: {
        Loader,
        Nav,
    },

    setup() {
        const router = useRouter()
        const state = reactive({
            search: '',
            currency: {},
            assets: [],
            selectedCurrency: null,
            loaded: true,
            currencyFound: null,
        })

        const store = useStore()

        const addCurrency = async () => {
            try {
                const document = await firebaseCurrencies.add({
                    ...state.selectedCurrency,
                    updatedAt: Date.now(),
                    status: 'flat',
                    uid: store.state.uid,
                })
                const currency = await document.get()

                if (currency.id) {
                    router.push({
                        name: 'ViewCurrency',
                        params: {
                            id: currency.id,
                        },
                    })
                }
            } catch (error) {
                errorHandler(error)
            }
        }

        // const loadCurrencies = async () => {
        //     try {
        //         const assets = await axios.get('https://api.coingecko.com/api/v3/coins/list')
        //         state.assets = await assets.data.sort((a, b) =>
        //             a.symbol.localeCompare(b.symbol)
        //         )
        //         state.loaded = true
        //     } catch (error) {
        //         errorHandler(error)
        //     }
        // }

        const loadAssets = async () => {
            try {
                const collection = await firebaseAssets
                    // .where('currencyId', '==', state.currency.key)
                    .get()

                state.assets = await collection.docs
                    .map(doc => ({
                        key: doc.id,
                        ...doc.data(),
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
            } catch (error) {
                errorHandler(error)
            }
        }

        const searchCurrency = async () => {
            if (state.search !== '') {
                try {
                    const found = await getAsset(state.search)
                    if (found) {
                        console.log('asset', found)

                        try {
                            const currency = {
                                symbol: found[0].asset_id,
                                id: found[0].name.toLowerCase(),
                                name: found[0].name,
                            }

                            const alreadyExist = state.assets.find(
                                asset => asset.symbol === currency.symbol
                            )
                            if (!alreadyExist) {
                                await firebaseAssets.add({
                                    ...currency,
                                    createdAt: Date.now(),
                                    ...(found[0].price_usd && { price_usd: found[0].price_usd }),
                                })
                            }

                            await loadAssets()
                            state.currencyFound = currency
                            state.selectedCurrency = currency
                        } catch (error) {
                            errorHandler(error)
                        }
                    }
                } catch (error) {
                    errorHandler(error)
                }
            }
        }
        // loadCurrencies()

        loadAssets()

        return {
            ...toRefs(state),
            addCurrency,
            searchCurrency,
        }
    },
}
</script>

<style lang="scss" scoped></style>
